<template>
  <div>
    <section class="text-idBlued">
      <h5>SECTION A:</h5>
      <h2>BIO-DATA</h2>
    </section>

    <section class="px-6 py-4 rounded-lg bg-red-100 mt-7 w-full">
      <p class="text-sm font-bold">
        Fill the fields provided below with your correct details
      </p>
      <p class="text-xs font-medium text-dkgray mt-2.5">
        Confirm that the details presented are yours before proceeding to fill
        anything
      </p>
    </section>

    <form @submit.prevent="proceed">
      <div>
        <label for="verificationCode">
          Verification Number<span class="req">*</span>
        </label>
        <input
          id="verificationCode"
          type="text"
          v-uppercase
          v-model="form.verificationCode"
          class="input"
          readonly
        />
      </div>

      <div>
        <label for="employmentNumber">
          Employment Number
          <!-- <span class="req">*</span> -->
        </label>
        <input
          id="employmentNumber"
          type="text"
          v-uppercase
          v-model="form.employmentNumber"
          class="input"
        />
      </div>

      <div>
        <label for="title"> Title<span class="req">*</span> </label>
        <v-select
          id="title"
          label="title"
          :reduce="(b) => b.title"
          :options="$store.state.staff.titles"
          v-uppercase
          v-model="form.title"
          placeholder="-- select title --"
        ></v-select>
      </div>

      <div>
        <label for="lastName"> Last Name<span class="req">*</span> </label>
        <input
          id="lastName"
          type="text"
          v-uppercase
          v-model="form.lastName"
          class="input"
          readonly
        />
      </div>

      <div>
        <label for="firstName"> First Name<span class="req">*</span> </label>
        <input
          id="firstName"
          type="text"
          v-uppercase
          v-model="form.firstName"
          class="input"
          readonly
        />
      </div>

      <div>
        <label for="middleName"> Middle Name<span class="req">*</span> </label>
        <input
          id="middleName"
          type="text"
          v-uppercase
          v-model="form.middleName"
          class="input"
        />
        <small
          v-if="form.middleName && !middleNameReady"
          class="text-red-500 text-xs"
        >
          Make sure you enter your middle name in full, no abbreviations or
          initials
        </small>
      </div>

      <div>
        <label for="dateOfBirth">
          Date Of Birth<span class="req">*</span>
        </label>
        <input
          id="dateOfBirth"
          type="date"
          v-uppercase
          v-model="form.dateOfBirth"
          class="input"
          readonly
        />
      </div>

      <div>
        <label for="gender"> Gender<span class="req">*</span> </label>
        <v-select
          id="gender"
          label="label"
          :reduce="(b) => b.value"
          :options="genders"
          v-uppercase
          v-model="form.gender"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="maritalStatus">
          Marital Status <span class="req">*</span>
        </label>
        <v-select
          id="maritalStatus"
          label="label"
          :reduce="(b) => b.value"
          :options="maritalStatues"
          v-uppercase
          v-model="form.maritalStatus"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="nationality"> Nationality<span class="req">*</span> </label>
        <v-select
          id="nationality"
          label="countryName"
          :reduce="(b) => b.countryName"
          :options="$store.state.staff.countries"
          v-uppercase
          v-model="form.nationality"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="stateOfOrigin">
          State of Origin<span class="req">*</span>
        </label>
        <v-select
          id="stateOfOrigin"
          label="stateName"
          :reduce="(b) => b"
          :options="$store.state.staff.states"
          v-uppercase
          v-model="form.stateOfOrigin"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="lga"> LGA<span class="req">*</span> </label>
        <v-select
          id="lga"
          label="lgaName"
          :reduce="(b) => b"
          :options="lgas"
          v-uppercase
          v-model="form.lga"
          placeholder="-- select --"
          :disabled="!form.stateOfOrigin"
        ></v-select>
      </div>

      <div>
        <label for="city"> City/Town<span class="req">*</span> </label>
        <v-select
          id="city"
          label="cityName"
          :reduce="(b) => b.cityName"
          :options="cities"
          v-uppercase
          v-model="form.city"
          placeholder="-- select --"
          :disabled="!form.lga"
        ></v-select>
      </div>

      <div>
        <label for="phone"> Phone<span class="req">*</span> </label>
        <input
          id="phone"
          type="text"
          v-uppercase
          v-model="form.phone"
          class="input"
          readonly
        />
      </div>

      <div>
        <label for="email"> Email<span class="req">*</span> </label>
        <input
          id="email"
          type="email"
          v-uppercase
          v-model="form.email"
          class="input"
          readonly
        />
      </div>

      <div>
        <label for="homeAddress">
          Home Address<span class="req">*</span>
        </label>
        <input
          id="homeAddress"
          type="text"
          v-uppercase
          v-model="form.homeAddress"
          class="input"
        />
        <!-- required -->
      </div>

      <div v-if="!$store.getters['staff/stepStatus'](0)">
        <button :disabled="proceeding || !formReady" type="submit">
          <span>Save and Proceed</span>
          <spinner v-if="proceeding"></spinner>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchLgas, fetchCities } from "@/services/sourceData";
export default {
  name: "Personal",

  data() {
    return {
      proceeding: false,
      form: {
        verificationCode: this.$store.state.staff.profile.verificationCode,
        employmentNumber: this.$store.state.staff.profile.employmentNumber,
        title: this.$store.state.staff.profile.title,
        lastName: this.$store.state.staff.profile.lastName,
        firstName: this.$store.state.staff.profile.firstName,
        middleName: this.$store.state.staff.profile.middleName,
        dateOfBirth: new Date(this.$store.state.staff.profile.dateOfBirth)
          .toISOString()
          .substring(0, 10),
        gender: this.$store.state.staff.profile.gender,
        maritalStatus: this.$store.state.staff.profile.maritalStatus,
        nationality: this.$store.state.staff.profile.nationality ?? null,
        stateOfOrigin: this.$store.state.staff.profile.stateOfResidence,
        lga: this.$store.state.staff.profile.townOfResidence,
        city: this.$store.state.staff.profile.cityOfResidence,
        phone: this.$store.state.staff.profile.phoneNumber,
        email: this.$store.state.staff.profile.email,
        homeAddress: this.$store.state.staff.profile.homeAddress,
      },
      requiredForm: [
        "verificationCode",
        // "employmentNumber",
        "title",
        "lastName",
        "firstName",
        "middleName",
        "dateOfBirth",
        "gender",
        "maritalStatus",
        "nationality",
        "stateOfOrigin",
        "lga",
        "city",
        "phone",
        "email",
        "homeAddress",
      ],
      genders: [
        { label: "FEMALE", value: "F" },
        { label: "MALE", value: "M" },
      ],
      maritalStatues: [
        { label: "SINGLE", value: "S" },
        { label: "MARRIED", value: "M" },
        { label: "DIVORCED", value: "D" },
        { label: "WIDOWED", value: "W" },
      ],
      lgas: [],
      cities: [],
      step: this.$store.getters["staff/step"],
    };
  },

  computed: {
    dob() {
      if (this.$store.state.staff.profile.dateOfBirth) {
        return new Date(this.$store.state.staff.profile.dateOfBirth)
          .toISOString()
          .substring(0, 10);
      }
      return null;
    },
    middleNameReady() {
      return Boolean(this.form.middleName) && this.form.middleName.length >= 3;
    },
    formReady() {
      let ready = true;
      if (!this.middleNameReady) return false;

      for (const fd of this.requiredForm) {
        if (this.form[fd] === null || this.form[fd] === "") {
          ready = false;
        }
      }

      return ready;
    },
  },

  mounted() {
    this.$store.commit("staff/updateBStep", 1);
  },

  watch: {
    async "form.stateOfOrigin"(nv) {
      if (nv) {
        this.lgas = await fetchLgas(this.form.stateOfOrigin.stateCode);
      }
    },
    async "form.lga"(nv) {
      if (nv) {
        this.cities = await fetchCities(this.form.lga.lgaCode);
      }
    },
  },

  methods: {
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure this information is correct? If you click YES to proceed, you will NOT be able to come back to it again.",
          showDenyButton: true,
          confirmButtonText: "YES",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.proceeding = true;

        this.form.stateOfOrigin = this.form.stateOfOrigin.stateName;
        this.form.lga = this.form.lga.lgaName;

        const res = await this.$http.patch("/employee/bio-data", this.form);

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("staff/updateData", {
          data: data.data,
          key: "profile",
        });

        this.$store.commit("staff/updateVerifyStep", 1);
        this.$store.commit("staff/updateBStep", 2);

        this.$router.push({ name: "Staff Section B" });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
